<template>
    <div class="create-paper">
        <el-dialog title="业务管理"
                   :close-on-click-modal="false"
                   :before-close="paperCreate.dialogFormClose"
                   :visible.sync="paperCreate.dialogState.value">
            <div class="form" style="margin-top: 0">
                <el-form :model="paperCreate.state.form" :rules="paperCreate.state.rules" ref="ruleForm">
                    <el-row :gutter="20" v-if="paperCreate.group['value'].group_interface_status === 1" >
                        <el-col :span="20">
                            <el-form-item label="接口模式" prop="interface_mode" style="margin-bottom: 0">
                                <el-radio  :label="0" v-model.number="paperCreate.state.form.interface_mode" value="0" > 全开</el-radio>
                                <el-radio  :label="1" v-model.number="paperCreate.state.form.interface_mode" value="1" > 半开</el-radio>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" v-if="paperCreate.state.form.interface_mode === 1">
                            <el-form-item label="超出以下字数限制接口处理" prop="interface_max_num" style="margin-bottom: 0">
                                <el-input v-model.number="paperCreate.state.form.interface_max_num"  placeholder="如10000"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" v-if="paperCreate.group.value.group_interface_info === 'wpcheck'">
                            <el-form-item label="选择对应商品Id" v-if="paperCreate.options['value']" prop="interface_related_id" style="margin-bottom: 0">
                                <el-select v-model.number="paperCreate.state.form.interface_related_id" placeholder="请选择" style="width: 100%">
                                    <el-option
                                            v-for="item in paperCreate.options['value']"
                                            :key="item.checkCode"
                                            :label="item.name + '/维普成本价(' + item.buyerPrice + ')/售价('+item.price+')'"
                                            :value="item.checkCode">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="业务名称" prop="name">
                        <el-input v-model="paperCreate.state.form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="公告" prop="announcement">
                        <el-input type="textarea" v-model="paperCreate.state.form.announcement"></el-input>
                    </el-form-item>
                    <el-form-item label="业务标识" prop="type">
                        <el-input v-model="paperCreate.state.form.type"></el-input>
                    </el-form-item>

                    <el-row :gutter="20">
                        <el-col :span="16">
                            <el-form-item label="业务成本金额" prop="cost_money">
                                <el-input v-model="paperCreate.state.form.cost_money" ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="业务成本字数" prop="cost_num">
                                <el-input v-model.number="paperCreate.state.form.cost_num"  placeholder="字数,最少输入"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="16">
                            <el-form-item label="业务金额" prop="money">
                                <el-input v-model="paperCreate.state.form.money" ></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="业务字数" prop="num">
                                <el-input v-model="paperCreate.state.form.num" placeholder="字数,最少输入"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="标题最小字数">
                                <el-input v-model="paperCreate.state.form.title_min" type="number" placeholder="标题字数,最少输入"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="标题最大字数">
                                <el-input v-model="paperCreate.state.form.title_max" type="number" placeholder="标题字数,最少输入"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="内容最小字数">
                                <el-input v-model="paperCreate.state.form.content_min" type="number" placeholder="标题字数,最少输入"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="6">
                            <el-form-item label="内容最大字数">
                                <el-input v-model="paperCreate.state.form.content_max" type="number" placeholder="标题字数,最少输入"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="系统主要适用于" prop="interface">
                        <el-input v-model="paperCreate.state.form.interface" placeholder="" ></el-input>
                    </el-form-item>

                    <el-form-item label="淘宝宝贝地址" prop="tb_url">
                        <el-input v-model="paperCreate.state.form.tb_url" placeholder="https://"></el-input>
                    </el-form-item>

                    <el-form-item label="时间选填" prop="on_date" >
                        <el-radio  :label="1" v-model="paperCreate.state.form.on_date" value="1" border> 是</el-radio>
                        <el-radio  :label="0" v-model="paperCreate.state.form.on_date" value="0" border> 否</el-radio>
                    </el-form-item>

                    <el-form-item label="热门" prop="attr" >
                        <el-radio  :label="1" v-model="paperCreate.state.form.attr" value="1" border> 是</el-radio>
                        <el-radio  :label="0" v-model="paperCreate.state.form.attr" value="0" border> 否</el-radio>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">{{ paperCreate.btnText.value }}</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import usePaperCreate from "../../composables/paper/use-paperCreate";
    export default {
        name: "paperCreate",
        props:{
            paperGroup:{
                type: Object
            },
            dialogFormVisiblePaperCreateState: {
                type: Boolean
            },
            paper:{
                type: Object
            }
        },
        setup(props, context){
            const paperCreate = usePaperCreate(props, context);
            return{
                paperCreate,
            }
        },
        methods:{
            onSubmit(){
                this.$refs['ruleForm'].validate( (valid) => {
                    if (valid) {
                        return this.paperCreate.submitStorePaper(this.form)
                    } else {
                        // this.$message.error('error submit!!');
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .create-paper{
        background: #fff;
        padding: 15px;
    }
    .form{
        margin-top: 35px;
    }
</style>