import {post, get, update, destroy} from '@/utils/http'

export function getPaperGroups(t) {
    return get({
        url: 'admin/paper-groups',
        method: 'get',
        params: t
    })
}

export function storePaperGroups(param) {
    return post({
        url:'admin/paper-groups',
        method: 'post',
        params: param
    })
}

export function updatePaperGroups(id, param) {
    return update({
        url:'admin/paper-groups/',
        method: 'update',
        params: param
    })
}

export function deletePaperGroup(param) {
    return destroy({
        url:'admin/paper-groups/',
        method: 'delete',
        params: param
    })
}