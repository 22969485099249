import {post, get, update, destroy} from '@/utils/http'

export function getPapers(t) {
    return get({
        url: 'admin/papers?admin=true',
        method: 'get',
        params: t
    })
}

export function getWeiPuProducts() {
    return post({
        url: 'admin/interface/wp/getProducts',
        method: 'post',
    })
}

export function storePaper(param) {
    return post({
        url:'admin/papers',
        method: 'post',
        params: param
    })
}

export function updatePaper(param) {
    return update({
        url:'admin/papers/',
        method: 'PATCH',
        params: {...param, _method: 'PATCH'}
    })
}

export function deletePaper(param) {
    return destroy({
        url:'admin/papers/',
        method: 'delete',
        params: param
    })
}