// eslint-disable-next-line no-unused-vars
import {reactive, ref, onMounted, toRefs} from "@vue/composition-api";
import {getPaperGroups, storePaperGroups, deletePaperGroup, updatePaperGroups} from "@/api/papergroup";
import { Message } from 'element-ui';
// eslint-disable-next-line no-unused-vars
import {deletePaper} from "../api/paper";

export default function usePaperGroup() {
    const page = ref(1);
    const state = reactive({
        loadNodeData: ref([]),
        props: ref([]),
        dialogFormVisible: ref(false),
        dialogFormVisiblePaperCreateState: ref(false),
        groupId: ref(0),
        paperGroup: ref({}),
        loading: ref(true),
        paper: ref({}),
        currGroup: ref({})
    });
    const defaultProps = reactive({
        label: "label",
        children: "papers",
    });


    function dialogTableVisibleCreate(){
        state.dialogFormVisible = !state.dialogFormVisible
        console.log(state.dialogFormVisible);
    }

    function editNode(node, data){
        console.log('data', data)
        data.updated = true;
        state.paperGroup = node.parent.data;
        state.paper = {...data, label: data.name};
        state.dialogFormVisiblePaperCreateState = true;
    }


    function appendNode(node, data) {
        state.paperGroup = null;
        state.paperGroup = data;
        state.groupId = data.id // 分组id
        state.paper = {
            'group_interface_status': 1,
            'interface_mode' : 0
        };
        state.dialogFormVisiblePaperCreateState = true
        // const newChild = {};
        // if (!data.children) {
        //     this.$set(data, 'children', []);
        // }
        // data.children.push(newChild);
    }

    // eslint-disable-next-line no-unused-vars
    function editGroup(node, data){
        console.log('editGroup')
        state.dialogFormVisible = true
        state.currGroup = node.data
    }

    async function submitUpdate(formData) {
        const paperGroup = await updatePaperGroups(formData.id, {...formData, '_method': "PATCH"});
        if(paperGroup.code === 20000){
            Message({
                message: paperGroup.msg,
                type: 'success'
            });
            state.dialogFormVisible = false
            getOrderList().then(r => r)
        }
    }


    async function submitCreate(formData){
        const paperGroup = await storePaperGroups(formData);
        if(paperGroup.code === 20000){
            Message({
                message: paperGroup.msg,
                type: 'success'
            });
            state.dialogFormVisible = false
            getOrderList().then(r => r)
        }
    }
    async function getOrderList(page=1){
        const paperGroups = await getPaperGroups({page});
        state.props = paperGroups.data;
        state.loading = false
    }

    function submitCreatePaper(){
        state.dialogFormVisiblePaperCreateState = false;
        getOrderList(page.value).then(r => r)
    }

    async function remove(node, data){
        const parent = node.parent;
        const children = parent.childNodes;
        const index = children.findIndex(d => d.data.id === data.id);
        if(node.level > 1){
            //删除业务
            const rsp = await deletePaper(data);
            if(rsp.message === 'success'){
                children.splice(index, 1);
                Message({
                    message: "删除成功",
                    type: 'success'
                });
            }
        }
        if(node.level === 1){
            if(node.childNodes.length > 0){
                Message({
                    message: "此分组下有业务, 删除失败!",
                    type: 'warning'
                });
                return false;
            }
            // eslint-disable-next-line no-unused-vars
            const response = await deletePaperGroup({
                id: node.data.id
            });
            console.log(response)
            if(response.code === 20000){
                Message({
                    message: "删除成功",
                    type: 'success'
                });
                children.splice(index, 1);
            }
        }
    }

    onMounted( () => {
        getOrderList(page.value).then(r => r)
    });

    return {
        state,
        dialogTableVisibleCreate,
        submitCreate,
        defaultProps,
        appendNode,
        editNode,
        remove,
        submitCreatePaper,
        editGroup,
        submitUpdate
    }
}