// eslint-disable-next-line no-unused-vars
import {reactive, ref, watch, computed} from "@vue/composition-api";
import {storePaper, getWeiPuProducts, updatePaper} from "@/api/paper"
import {Message} from "element-ui";

// eslint-disable-next-line no-unused-vars
export default function usePaperCreate(props, context) {
    const checkNum = (rule, value, callback) => {
        if (!value) {
            return callback(new Error('业务成本字数不能为空'));
        }
        setTimeout(() => {
            if (value < 1) {
                callback(new Error('必须大于1'));
            } else {
                callback();
            }
        }, 1000);
    };
    const state = reactive({
        form: {
            name: '',
            announcement: '',
            type: '',
            money: null,
            num: null,
            'title_min': 1,
            'title_max': 60,
            'content_min': 100,
            'content_max': 600000,
            attr: "0",
            cost_num: '',
            cost_money: '',
            on_date: "0",
            tb_url: '',
            interface: '广大用户',
            interface_mode: 1 ,
            interface_max_num: '',
            group_id: 0,
        },
        rules: {
            name: [{required: true, message: '请输入业务名称', trigger: 'blur'}, {
                min: 3,
                max: 150,
                message: '长度在 3 到 150 个字符',
                trigger: 'blur'
            }],
            type: [
                {required: true, message: '请输入业务标识', trigger: 'blur'},
                {min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur'}
            ],
            num: [{required: true, message: '请输入业务最少字符', trigger: 'blur'}],
            money: [{required: true, message: '请填写正确的金额,保留两位小数', pattern: /(^[\d]|^[1-9][\d]*)($|[.][\d]{0,2}$)/}],
            cost_num: [{validator: checkNum, trigger: 'blur'}],
            cost_money: [{required: true,message: '请填写业务成本的金额,保留两位小数',pattern: /(^[\d]|^[1-9][\d]*)($|[.][\d]{0,2}$)/}],
            group_id: [{type: 'number', required: true, message: '错误的分组id', trigger: 'blur'}]
        },
    });
    const dialogState = ref(false);
    const options = ref([]);
    const groupInterface = ref(-1);
    const group = ref({});
    const btnText = ref("立即创建");

    watch(() => props.dialogFormVisiblePaperCreateState, (val) => {
        clearForm()
        dialogState.value = val;
        state.form.group_id = props.paperGroup.id;
        if(val === true){
            WeiPuProducts().then(r => r)
        }
    });

    // eslint-disable-next-line no-unused-vars
    watch(() => props.paperGroup, (val) => {
        if(props.paper !== null){
            // props.paper.updated = true;
            state.form = props.paper;
            // delete val.papers;
            group.value = (val);
            groupInterface.value = val.group_interface_status;
        }
    })

    watch(() => props.paper, (val) => {
        if(val){
            state.form.group_id = props.paperGroup.id;
            state.form = val;
            if(val.hasOwnProperty('updated')){
                btnText.value =  '立即更新';
            }
            // state.form = val;
        }
    })

    function dialogFormClose() {
        dialogState.value = false;
        context.parent.state.dialogFormVisiblePaperCreateState = false
        state.form.group_id = 0
    }

    /**
     * 获取维普上的商品
     * @returns {Promise<void>}
     * @constructor
     */
    async function WeiPuProducts(){
         const products =  await getWeiPuProducts();
        options.value = products
    }

    /**
     * 更新数据
     */
    function sumitUpdatePaper(){
        updatePaper(state.form).then(() => {
            dialogState.value = false;
            context.parent.state.dialogFormVisiblePaperCreateState = false;
            state.form.group_id = 0;
            Message({
                message: '更新成功!',
                type: 'success'
            });
            //清空表单内容
            clearForm()
            context.emit('submit:create:paper')
        })
    }

    /**
     * 请求添加/更新
     */
    function submitStorePaper() {
        if(state.form.hasOwnProperty('updated')){
            sumitUpdatePaper()
            return;
        }
        storePaper(state.form).then(() => {
            state.form.group_id = 0;
            Message({
                message: '添加成功!',
                type: 'success'
            });
            context.emit('submit:create:paper')
            //清空表单内容
            // clearForm(true)
        }).catch((e)=>{
            let msg = '';
            Object.keys(e).map((k) => msg+=e[k][0]+"<br/>");
            Message({type: 'error', dangerouslyUseHTMLString: true,message: msg})
        })

    }
    //清空表单内容
    function clearForm(created=false){
        if(created){
            state.form = null;
        }else{
            Object.keys(state.form).forEach(function(index) {
                if (state.form.hasOwnProperty(index) && !index.includes(['title_min', 'title_max', 'content_min',
                    'content_max', 'interface_mode', 'on_date', 'attr'])) {
                    state.form[index] = null
                }
            });
        }
    }

    return {
        state,
        dialogFormClose,
        submitStorePaper,
        dialogState,
        groupInterface,
        options,
        group,
        btnText
    }
}
