<template>
    <div class="paper-group">
        <div
                v-loading="state.loading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="#fff"
        >
            <el-container>
                <div class="grid-content">
                    <el-button type="primary" icon="el-icon-plus" size="small" @click="dialogTableVisibleCreate"> 添加</el-button>
                </div>
            </el-container>
            <div class="groups" >
                <el-tree
                        style="margin-top: 1%"
                        :data="state.props"
                        :props="defaultProps"
                        :expand-on-click-node="false"
                        default-expand-all
                        :show-checkbox="false">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{ node.label }}</span>
                    <span style="margin-left: 15px">
                      <el-button
                              v-if="node.level === 1"
                              type="text"
                              size="mini"
                              @click="() => editGroup(node, data)">
                          编辑
                      </el-button>
                         <el-button
                                 v-if="node.level === 1"
                                 type="text"
                                 size="mini"
                                 @click="() => appendNode(node, data)">
                          添加业务
                      </el-button>
                        <el-button
                                v-if="node.level > 1"
                                type="text"
                                size="mini"
                                @click="() => editNode(node, data, 'update')">
                          修改业务
                      </el-button>
                        <el-popconfirm
                                @onConfirm="remove(node, data)"
                                title="确定删除吗？"
                        >
                          <el-button type="text" size="mini" slot="reference">删除</el-button>
                        </el-popconfirm>
                    </span>
                </span>
                </el-tree>
                <paper-group-create
                        @submit:create="submitCreate"
                        @submit:update="submitUpdate"
                        :groupId="state.groupId"
                        :group="state.currGroup"
                        :dialogFormVisible="state.dialogFormVisible"
                />
                <paper-create
                        @submit:create:paper="submitCreatePaper"
                        :paperGroup="state.paperGroup"
                        :paper="state.paper"
                        :dialogFormVisiblePaperCreateState="state.dialogFormVisiblePaperCreateState"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import usePaperGroup from '@/composables/use-papergroup';
    import paperGroupCreate from "./create";
    import PaperCreate from "../create";
    export default {
        name: "papergroupList",
        setup(){

            const {dialogTableVisibleCreate,
                state,
                submitCreate,
                defaultProps,
                appendNode,
                submitCreatePaper,
                editNode,
                remove,
                editGroup,
                submitUpdate
            } = usePaperGroup();
            return {
                dialogTableVisibleCreate,
                state,
                submitCreate,
                defaultProps,
                submitCreatePaper,
                appendNode,
                editGroup,
                remove,
                editNode,
                submitUpdate
            }
        },
        components:{paperGroupCreate, PaperCreate}
    }
</script>

<style scoped lang="stylus">
    .paper-group
        background #ffffff
        padding 15px
</style>