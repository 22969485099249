<template>
    <div>
        <el-dialog :title="btnText+'业务分组'"
                   :before-close="dialogFormClose"
                   :visible.sync="dialogFormVisibleState">
            <el-form :model="formData">
                <el-form-item label="分组名称" prop="group_name">
                    <el-input v-model="formData.group_name"></el-input>
                </el-form-item>
                <el-form-item label="分组状态" prop="group_status">
                    <el-switch v-model="formData.group_status"></el-switch>
                </el-form-item>
                <el-form-item label="接口处理" prop="group_interface_status">
                    <el-switch v-model="formData.group_interface_status"></el-switch>
                </el-form-item>
                <el-form-item label="接口信息" prop="desc" v-show="formData.group_interface_status">
                    <el-select v-model="formData.group_interface_info" placeholder="请选择接口信息" style="width: 100%">
                        <el-option label="维普检测" value="wpcheck"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormClose">取 消</el-button>
                <el-button type="primary" @click="handleSubmit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    // eslint-disable-next-line no-unused-vars
    import { reactive, watch, ref} from '@vue/composition-api'
    export default {
        name: "paperGroupCreate",
        props:{
            dialogFormVisible:{
                type: Boolean
            },
            groupId: {
                type: Number,
                default(){
                    return 0
                }
            },
            group: {
                type: Object
            }
        },
        // eslint-disable-next-line no-unused-vars
        setup(props, context){
            const dialogFormVisibleState = ref(false);
            const formData = reactive({
                group_name: '',
                group_status: true,
                group_interface_status: false,
                group_interface_info: ''
            });
            const btnText = ref('添加');
            // eslint-disable-next-line no-const-assign
            watch(() => props.dialogFormVisible, (val) => {
                dialogFormVisibleState.value = val
                if(val == false){
                    formData.group_name = '';
                    formData.group_interface_status = false;
                    formData.group_interface_info = ''
                }
            })
            watch(() => props.group, (group) => {
                console.log(Object.keys(group).length)
                if(Object.keys(group).length > 0){
                    formData.group_name = group.group_name
                    formData.group_status = group.group_status === 1
                    formData.group_interface_status = group.group_interface_status === 1
                    formData.group_interface_info = group.group_interface_info
                    formData.id = group.id
                    btnText.value = '更新';
                }else{
                    btnText.value = '添加';
                }
            })
            function dialogFormClose(){
                context.parent.state.dialogFormVisible = false
                context.parent.state.currGroup = {}
            }
            function handleSubmit(){
                if(btnText.value === '更新'){
                    context.emit('submit:update', formData)
                }
                if(btnText.value === '添加'){
                    context.emit('submit:create', formData)
                }
            }

            return {
                formData,
                dialogFormVisibleState,
                dialogFormClose,
                handleSubmit,
                btnText
            };
        }
    }
</script>

<style scoped>

</style>